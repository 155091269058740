@import '../../theme.module.scss';

.results {
  position: relative;
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 100px;

  .list {
    position: relative;
    width: 100%;
    padding-left: 125px;
    box-sizing: border-box;
    margin-top: 16px;

    .contained {
      position: relative;
      width: 720px;

      .recent_image {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 8px 0;

        & > p {
          position: relative;
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          color: #fff;
          margin: 4px 0;
        }

        & > img {
          position: relative;
          height: 180px;
          width: 150px;
          border-radius: 4px;
          object-fit: cover;
          object-position: center;
        }
      }

      .settings {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;

        .country {
          position: relative;

          & > p {
            color: #fff;
            font-weight: bold;
          }
        }
      }

      .include {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 8px;

        & > p {
          color: #fff;
          font-weight: 300;

          span {
            font-weight: 600;
            margin-left: 6px;
          }
        }
      }

      .items {
        position: relative;
        width: 100%;
        margin-top: 30px;
        padding-left: 2px;
        box-sizing: border-box;

        & > ul {
          position: relative;
          width: 100%;
          list-style-type: none;
          list-style: none;

          & > li {
            position: relative;
            width: 100%;
          }

          & > li:not(:nth-child(1)) {
            margin-top: 30px;
          }
        }

        .link {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: flex-start;

          & > p {
            font-weight: 300;
            font-size: 14px;
            color: #fff;
            margin-left: 2px;
          }
        }

        .title {
          position: relative;
          width: 100%;
          box-sizing: border-box;

          & > p:nth-child(1) {
            font-weight: 500;
            font-size: 34px;
            width: 100%;

            a {
              font-weight: 500;
              font-size: 34px;
              width: 100%;
              color: #fff;
              cursor: pointer;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .message {
          position: relative;
          width: 100%;

          & > p:nth-child(1) {
            font-weight: 300;
            font-size: 16px;
            width: 100%;
            color: #fff;
            opacity: 0.8;
            margin-left: 2px;

            a {
              color: #fff;
            }
          }
        }
      }

      .see_more {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #454545;
        margin-top: 30px;
        margin-bottom: 20px;
        box-sizing: border-box;

        &:hover {
          background: #525252;
        }

        & > a {
          text-decoration: none;
          position: relative;
          height: 44px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          p {
            color: #fff;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
          }
        }
      }

      .blog {
        position: relative;
        width: 100%;
        margin-top: 20px;

        & > p {
          color: #fff;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .results {
    width: 100vw;
    box-sizing: border-box;

    .list {
      padding-left: 12px;
      width: 100vw;

      .contained {
        width: 94vw;
        box-sizing: border-box;

        .items {
          box-sizing: border-box;
        }
      }
    }
  }
}
