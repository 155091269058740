@import '../../theme.module.scss';

.header {
  position: relative;
  height: 110px;
  width: 100%;
  background: #161616;
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .logo {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;

    & > img {
      height: 40px;
      width: 40px;
      object-fit: contain;
      object-position: center;
    }
  }

  .input {
    position: relative;
    width: 750px;
    height: 100%;
    margin-left: 24px;
    box-sizing: border-box;

    &_text {
      position: relative;
      width: 100%;
      height: 44px;
      background: $inputTextColor;
      border-radius: 4px;
      padding: 4px 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: 38px;

      & > p {
        color: #fff;
        font-weight: 500;
        font-size: 16px;
      }
    }

    .search {
      position: absolute;
      right: 8px;
      height: 36px;
      top: 3px;
      border: none;
      box-shadow: none;
      background: transparent;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    .bar {
      position: absolute;
      height: 34px;
      width: 100%;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;

      .all {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-right: 4px;
        border-bottom: 2px solid #769dff;

        svg {
          path {
            fill: #769dff;
          }
        }

        & > p {
          margin-left: 4px;
          color: #769dff;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding-right: 10px;
    padding-left: 30px;
    width: 100vw;

    .input {
      margin-left: 16px;
      width: calc(100% - 70px);

      &_text {
        & > p {
          color: #fff;
          font-weight: 500;
          font-size: 13px;
        }
      }

      .bar {
        position: absolute;
        left: -55px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .header {
    padding-right: 10px;
    padding-left: 20px;
    width: 100vw;
  }
}
