@import '../../theme.module.scss';

@keyframes shine {
  0% {
    left: -100px;
  }
  20% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.home {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .contained {
    position: relative;
    width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .logo {
      position: relative;
      height: 160px;
      width: 180px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        position: relative;
        height: 160px;
        width: 180px;
        object-fit: contain;
        object-position: center;
      }
    }

    .input {
      position: relative;
      width: 550px;
      background: $inputTextColor;
      border-radius: 4px;
      height: 50px;
      margin: 16px 0;
      margin-top: 20px;
      padding: 4px 8px;
      box-sizing: border-box;

      & > input {
        position: relative;
        height: 100%;
        width: 100%;
        top: 0;
        box-sizing: border-box;
        background: transparent;
        box-shadow: none;
        border: none;
        outline: none;
        color: #fff;
        font-weight: 500;
      }

      .tip {
        position: absolute;
        right: 2px;
        bottom: calc(100% + 1px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #323333;
        padding: 4px 16px;
        border-radius: 4px;

        & > p {
          color: #fff;
          line-height: unset;
          font-weight: 500;
        }
      }

      .search {
        position: absolute;
        height: 38px;
        right: 8px;
        top: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        outline: none;
        box-shadow: none;
        width: 40px;
        border: none;
        cursor: pointer;
        overflow: hidden;

        & > p {
          color: #fff;
          font-size: 13px;
        }

        svg {
          path {
            fill: #fff;
          }
        }

        &:hover,
        &:active {
          &::before {
            content: '';
            position: absolute;
            width: 38px;
            height: 38px;
            border-radius: 4px;
            background-image: linear-gradient(
              120deg,
              rgba(255, 255, 255, 0) 30%,
              rgba(255, 255, 255, 0.8),
              rgba(255, 255, 255, 0) 70%
            );
            top: 0;
            left: -38px;
            animation: shine 2s infinite linear; /* Animation */
          }
        }
      }
    }

    .disclaimer {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & > p {
        color: #fff;
        font-size: 13px;
        font-weight: normal;
        text-align: center;

        a {
          color: #fff;
        }

        span {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .home {
    .contained {
      width: 94vw;

      .input {
        width: 100%;
        margin-top: 35px;
      }

      .disclaimer {
        padding: 4px 8px;
        box-sizing: border-box;
      }
    }
  }
}
